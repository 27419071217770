


































































import {Component, Prop, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, PlayerDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import moment from 'moment';
import DisplayName from '@/components/DisplayName.vue';
import PlayerName from '@/components/PlayerName.vue';
import ServerName from '@/components/ServerName.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: {DisplayName, PlayerName, ServerName}
})
export default class PlayerById extends Vue {
    @Prop({default: ''}) private id!: string;
    serverId: number | null = null;
    errorNoSuchPlayer = false;

    public tribeUrl(serverId: number, tribeId: number) {
        return `https://ark.domination-gaming.com/server/${serverId}/tribe/${tribeId}`;
    }

    public tribeUrlArkStats(serverId: number, tribeId: number) {
        return `/tribe/${serverId}-${tribeId}`;
    }

    public survivorUrl(serverId: number, survivorId: number) {
        return `https://ark.domination-gaming.com/server/${serverId}/survivor/${survivorId}`;
    }

    @AsyncComputedProp()
    public async player(): Promise<PlayerDto> {
        const player = (await ArkStatsBackendClient.getPlayers({
            filter: 'id==' + this.id,
            limit: 1
        })).data[0];
        if (player) {
            this.serverId = player.serverId ? player.serverId : null;
        } else {
            this.errorNoSuchPlayer = true;
        }
        return player;
    }

    public localDate(timestamp: string): string {
        return moment(timestamp).local().format('MMM D YYYY, HH:mm');
    }

}

